.Main {
    background-color: #ffffff;
    min-height: 100vh;
}

.Card, .CardText {
    background-color: #eceac5;
    border: #ffee00 1px solid;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.Card {
    margin: 1px;
    min-height: 65vh;
    border-radius: 25px;
}

.CardImage {
    border-radius: 25px;
    object-fit: contain;
    min-height: 60vh;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.CardMain {
    max-width: 100%;
    height: auto;
}

.MainCardHolder {
    text-align: center;
}

.MainCardImage {
    min-height: 72vh;
    border: #ffee00 1px solid;
}

.Button, .DropDown {
    background-color: #eceac5 !important;
    color: black !important;
    border: 1px solid #ffee00 !important;
}

.Button:hover {
    background-color: #eceac5 !important;
    border: 1px solid #ffee00 !important;
}

.DropDown {
    width: 100%;
    padding: 0;
    margin: 0;
}

.DropDownButton {
    width: 100%;
    padding: 0;
    margin: 0;
}

.DropdownMenu {
    border: none;
}

@media only screen and (max-width: 600px) {
    .Button {
        display: none;
    }
}